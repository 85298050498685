import classNames from "classnames";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import { useIsInEditMode } from "hooks";
import { PropsWithEpiFormContent } from "types/Props";

import {
    FormElements,
    Step,
    FormSummaryContainer,
    FormSummaryHeading,
    FormSummaryContent,
    FormSummaryDescription,
} from "./FormSummaryStep.styled";
import FormSummaryStepProps from "./FormSummaryStepProps";
import SubmissionAnswers from "./SubmissionAnswers";

export const ComponentName = "FormSummaryStepBlock";

const FormSummaryStep = ({
    id,
    className,
    formItems,
    hasSteps,
    children,
    open,
    active,
    done,
    formGuide,
    form,
}: FormSummaryStepProps): ReactElement => {
    const isInEditMode = useIsInEditMode();
    open = isInEditMode || open;
    active = isInEditMode || active;
    const [isOpen, setOpen] = useState<boolean>(open);
    const [isFormStep, _setIsFormStep] = useState<boolean>(
        !!(
            formItems &&
            (formItems[0]?.component === "FormStepBlock" ||
                formItems[0]?.component === "FormResultStepBlock" ||
                formItems[0]?.component === ComponentName)
        ),
    );
    const [step, setStep] = useState<PropsWithEpiFormContent>();
    const themeContext = useContext(ThemeContext);

    if (
        formItems &&
        (formItems[0]?.component === "FormStepBlock" ||
            formItems[0]?.component === "FormResultStepBlock" ||
            formItems[0]?.component === ComponentName) &&
        !step
    )
        setStep(formItems.shift());

    const theme = (themeContext && themeContext.theme) || "lightgray";
    useEffect(() => {
        if (isOpen !== open) {
            setOpen(open);
        }
    }, [isOpen, open]);

    const classes = classNames(className, {
        "has-step": step,
        open: open,
        closed: !open,
    });

    const childItems = <>{children}</>;

    if (hasSteps)
        return (
            <ThemeProvider
                theme={{
                    theme: theme,
                    isFormStep: isFormStep,
                    open: !isFormStep || isOpen,
                    done: done,
                    active: !isFormStep || active,
                    formGuide: formGuide,
                }}
            >
                <Step id={id} className={classes}>
                    <FormSummaryContainer>
                        {step && (
                            <>
                                <FormSummaryHeading>
                                    {step.label}
                                </FormSummaryHeading>
                                <FormSummaryDescription>
                                    {step.description}
                                </FormSummaryDescription>
                            </>
                        )}
                        <FormSummaryContent>
                            <FormElements>
                                <SubmissionAnswers form={form} id={id} />
                                {childItems}
                            </FormElements>
                        </FormSummaryContent>
                    </FormSummaryContainer>
                </Step>
            </ThemeProvider>
        );

    return <>{children}</>;
};

export default React.memo(FormSummaryStep);
