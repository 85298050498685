import React, { useEffect, useState, useCallback, useMemo } from "react";
import { FieldValues, useFormContext } from "react-hook-form";

import { Wrapper, Container, Heading } from "./SubmissionAnswers.styled";
import SubmissionAnswersProps from "./SubmissionAnswersProps";

import { PropsWithEpiContent } from "../../types/Props";
import { FormAnswerGroupItem, FormAnswersGroup } from "../../types/types";

const SubmissionAnswers = ({ form, id }: SubmissionAnswersProps) => {
    const { watch } = useFormContext();
    const stepComponents = useMemo(
        () => ["FormStepBlock", "FormResultStepBlock", "FormSummaryStepBlock"],
        [],
    );
    const selectiveComponents = useMemo(
        () => ["ChoiceElementBlock", "SelectionElementBlock"],
        [],
    );

    const [values, setValues] = useState<FieldValues>();
    const [answerGroups, setAnswerGroups] = useState([] as FormAnswersGroup[]);

    const GetFieldValue = useCallback(
        (
            formItem: PropsWithEpiContent,
            values: FieldValues,
        ): string | undefined => {
            const value = values[formItem.elementName];

            if (value === undefined || value === "") {
                return undefined;
            }

            if (
                selectiveComponents.includes(formItem.component!) &&
                formItem.items !== undefined
            ) {
                const selectItems = formItem.items as [
                    { caption: string; value: string },
                ];

                const selectItem = selectItems.find(
                    (item) => item.value === value,
                );
                if (selectItem) {
                    return selectItem.caption;
                }
            }

            if (
                formItem.component === "CurrencyAmountElementBlock" ||
                formItem.component === "CalculatedLoanAmountElementBlock"
            ) {
                return Number(value).toLocaleString("sv-SE");
            }

            return value;
        },
        [selectiveComponents],
    );

    const GetAdornment = useCallback(
        (formItem: PropsWithEpiContent): string | undefined => {
            return formItem.endAdornment;
        },
        [],
    );

    const groupAnswers = useCallback(
        (values: FieldValues): FormAnswersGroup[] => {
            const filterForm = () => {
                const filteredForm = [];
                for (const item of form) {
                    if (item.component === "FormSummaryStepBlock") {
                        break;
                    }
                    filteredForm.push(item);
                }
                return filteredForm.filter((item) =>
                    item.elementName.startsWith("__field_"),
                );
            };

            const groupedForm: FormAnswersGroup[] = [];
            let currentGroup: FormAnswerGroupItem[] = [];
            let groupLabel = "";

            filterForm().forEach((item) => {
                if (stepComponents.includes(item.component!)) {
                    if (currentGroup.length > 0) {
                        groupedForm.push({
                            label: groupLabel,
                            answers: currentGroup,
                        });
                        currentGroup = [];
                    }
                    groupLabel = item.label;
                } else {
                    const value = GetFieldValue(item, values);
                    const adornment = GetAdornment(item);
                    if (value !== undefined) {
                        currentGroup.push({
                            label: item.label,
                            value,
                            adornment,
                        });
                    }
                }
            });

            if (currentGroup.length > 0) {
                groupedForm.push({
                    label: groupLabel,
                    answers: currentGroup,
                });
            }

            return groupedForm;
        },
        [form, stepComponents, GetFieldValue, GetAdornment],
    );

    useEffect(() => {
        const subscription = watch((value) => {
            setValues(value);
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (values !== undefined) {
            setAnswerGroups(groupAnswers(values));
        }
    }, [values, groupAnswers]);

    return (
        <Wrapper>
            {answerGroups.map((group, index) => (
                <Container key={`submission-answer-${index}`}>
                    {group.label && (
                        <Heading id={`heading-${id}-${index}`}>
                            {group.label}
                        </Heading>
                    )}
                    <dl
                        aria-labelledby={
                            group.label ? `heading-${id}-${index}` : undefined
                        }
                    >
                        {group.answers.map((item, itemIndex) => (
                            <React.Fragment key={`answer-${itemIndex}`}>
                                <dt>{item.label}</dt>
                                <dd>
                                    {item.value} {item.adornment}
                                </dd>
                            </React.Fragment>
                        ))}
                    </dl>
                </Container>
            ))}
        </Wrapper>
    );
};

export default React.memo(SubmissionAnswers);
