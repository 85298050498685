import { styled } from "styled-components";

import {
    styleBodyM,
    styleLabelM,
    styleLabelS,
} from "style/components/Typography";
import { MQ } from "style/mediaQueries";

export const Wrapper = styled.div`
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    row-gap: 0.875rem;

    ${MQ.FROM_M} {
        grid-area: form;
        grid-column: col-start 7 / span 6;
        row-gap: 1.3125rem;
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 7 / span 5;
        row-gap: 1.75rem;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-flow: column wrap;
    row-gap: 2.1875rem;
    width: 100%;

    ${MQ.FROM_M} {
        row-gap: 2.625rem;
    }

    ${MQ.FROM_XL} {
        row-gap: 3.5rem;
    }

    dl {
        border-top: 1px solid var(--line-on-neutral-default);
        column-gap: 0;
        display: grid;
        grid-gap: 0;
        grid-template-columns: 1fr 1fr;

        dt,
        dd {
            border: none;
            border-bottom: 1px solid var(--line-on-neutral-default);
            color: var(--text-on-neutral-primary);
            line-height: 1;
            margin-left: 0;
            padding: 1.75rem;
            text-align: left;
        }

        dt {
            ${styleLabelS};
            padding-left: 0;
        }

        dd {
            ${styleBodyM};
            padding-right: 0;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin-bottom: 0.875rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
`;

export const Heading = styled.h3`
    ${styleLabelM};
    margin: 0;
`;
