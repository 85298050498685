import classNames from "classnames";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import { useIsInEditMode } from "hooks";
import { PropsWithEpiFormContent } from "types/Props";

import {
    FormElements,
    Step,
    FormResultContainer,
    FormResultHeading,
    FormResultContent,
    FormResultDescription,
} from "./FormResultStep.styled";
import FormResultStepProps from "./FormResultStepProps";

export const ComponentName = "FormResultStepBlock";

const FormResultStep = ({
    id,
    className,
    formItems,
    hasSteps,
    children,
    open,
    active,
    done,
    formGuide,
}: FormResultStepProps): ReactElement => {
    const isInEditMode = useIsInEditMode();
    open = isInEditMode || open;
    active = isInEditMode || active;
    const [isOpen, setOpen] = useState<boolean>(open);
    const [isFormStep, _setIsFormStep] = useState<boolean>(
        !!(
            formItems &&
            (formItems[0]?.component === "FormStepBlock" ||
                formItems[0]?.component === ComponentName ||
                formItems[0]?.component === "FormSummaryStepBlock")
        ),
    );
    const [step, setStep] = useState<PropsWithEpiFormContent>();
    const themeContext = useContext(ThemeContext);

    if (
        formItems &&
        (formItems[0]?.component === "FormStepBlock" ||
            formItems[0]?.component === ComponentName ||
            formItems[0]?.component === "FormSummaryStepBlock") &&
        !step
    )
        setStep(formItems.shift());

    const theme = (themeContext && themeContext.theme) || "lightgray";
    useEffect(() => {
        if (isOpen !== open) {
            setOpen(open);
        }
    }, [isOpen, open]);

    const classes = classNames(className, {
        "has-step": step,
        open: open,
        closed: !open,
    });

    const childItems = <>{children}</>;

    if (hasSteps)
        return (
            <ThemeProvider
                theme={{
                    theme: theme,
                    isFormStep: isFormStep,
                    open: !isFormStep || isOpen,
                    done: done,
                    active: !isFormStep || active,
                    formGuide: formGuide,
                }}
            >
                <Step id={id} className={classes}>
                    <FormResultContainer>
                        {step && (
                            <>
                                <FormResultHeading>
                                    {step.label}
                                </FormResultHeading>
                                <FormResultDescription>
                                    {step.description}
                                </FormResultDescription>
                            </>
                        )}
                        <FormResultContent>
                            <FormElements>{childItems}</FormElements>
                        </FormResultContent>
                    </FormResultContainer>
                </Step>
            </ThemeProvider>
        );

    return <>{children}</>;
};

export default React.memo(FormResultStep);
